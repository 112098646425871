import loadable from "@loadable/component";
import classnames from "classnames";
import { inject } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { RemoteList, RemoteListChildProps } from "../../components/remote/RemoteList.component";
import { Site } from "@ihr-radioedit/inferno-core";
import {
  BlockFragment,
  CurrentlyPlayingTrackDetailFragment,
  GetCurrentlyPlayingSongsQueryVariables,
} from "@ihr-radioedit/inferno-webapi";
import { MusicPageType } from "../../lib/catalog";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { getTrackHistoryWithCurrentlyPlaying, StationTrackOptions } from "../../services/LiveMeta";
import { getThemingCssFromTags } from "../../services/Sites.utils";
import { Container, FauxButton, Heading, Tab, Tabs } from "../../ui";
import { ButtonKind } from "../../ui/Button.component";
import type { PageBlockInterface } from "../Block.component";
import "./MusicTile.style.scss";
import { TopTrackLoader } from "./TopTrackLoader.component";
import { ICache } from "@ihr-radioedit/inferno-core";
import type { Store } from "@inferno/renderer-shared-core";
// import { ObservableMap } from "mobx";

const TrackList = loadable(() => import("../../components/catalog/TrackList.component"));

interface PlaylistProps extends PageBlockInterface {
  site?: Site;
  store?: Store;
  cache?: Map<string, ICache>;
}

interface MusicTileDisplayProps
  extends Pick<
    RemoteListChildProps<CurrentlyPlayingTrackDetailFragment, GetCurrentlyPlayingSongsQueryVariables>,
    "refresh"
  > {
  tracks: CurrentlyPlayingTrackDetailFragment[];
  block: BlockFragment;
  isPrimary: boolean;
  store?: Store;
}

const RECENTLY_PLAYED_IS_RECENT = 15 * 60 * 1000;
const isRecent = (timestamp?: number) => timestamp && Date.now() - timestamp * 1000 < RECENTLY_PLAYED_IS_RECENT;

export const MusicTileDisplay = inject("store")(
  ({ tracks, block, store, refresh, isPrimary }: MusicTileDisplayProps) => {
    if (!store) {
      return null;
    }
    const { site } = store;
    const { t } = useTranslation();

    useEffect(() => {
      refresh();
    }, [refresh]);
    if (!tracks.length || !isRecent(tracks[0]?.startTime)) {
      return <div key="music-tile-empty" />;
    }
    const playlistCss = classnames("component-playlist", "tile", getThemingCssFromTags(block.tags));
    const recentUrl = reverseRoute(site, "music_recently_played");
    const topSongsUrl = reverseRoute(site, "music_top_songs");
    return (
      <Container className={playlistCss} block={false} key="music-tile">
        <Heading level={2}>{t("station_music", { station: site.sections.general?.name })}</Heading>
        <Tabs selectedTab={block.type === "playlist" ? t("recently_played") : t("top_songs")}>
          <Tab label={t("recently_played")} key="recently_played">
            <TrackList
              data={tracks}
              pageType={MusicPageType.RECENT}
              currentTrackId={tracks[0]?.isCurrentlyPlayingTrack ? tracks[0]?.trackId : 0}
            />
            {recentUrl ? (
              <FauxButton className="see-all" to={recentUrl} kind={ButtonKind.SECONDARY} size="small">
                {`${t("see_all")} ${t("recently_played")}`}
              </FauxButton>
            ) : null}
          </Tab>
          <Tab label={t("top_songs")} key="top_songs">
            <TopTrackLoader isPrimary={isPrimary} block={block} songsToTake={3} />
            {topSongsUrl ? (
              <FauxButton className="see-all" to={topSongsUrl} kind={ButtonKind.SECONDARY} size="small">
                {`${t("see_all")} ${t("top_songs")}`}
              </FauxButton>
            ) : null}
          </Tab>
        </Tabs>
      </Container>
    );
  },
);

export const MusicTile = inject("store")(({ page, block, isPrimary, store }: PlaylistProps) => {
  if (!store) {
    return null;
  }
  const { site, cache } = store;
  const streamId = site.getStreamId();
  if (!site || !streamId || !page || !cache) {
    return null;
  }

  const recentlyPlayedOptions: StationTrackOptions = {
    slug: site.index.slug,
    paging: {
      take: 3,
    },
  };

  useEffect(() => {
    if (store && block) {
      store.storeBlock(block);
    }
  });

  // Playlist enabled on partners tab
  if (site.recentlyPlayedEnabled) {
    return (
      <RemoteList cursor={getTrackHistoryWithCurrentlyPlaying(recentlyPlayedOptions, cache)} showLoading={isPrimary}>
        {({ data: tracks, refresh }) => (
          <MusicTileDisplay refresh={refresh} tracks={tracks} block={block} isPrimary={isPrimary} />
        )}
      </RemoteList>
    );
  }
  return null;
});

export default MusicTile;
