import type { ICache } from "@ihr-radioedit/inferno-core";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { Cursor } from "@ihr-radioedit/inferno-core";
import { getSdk, sdkOpts } from "@ihr-radioedit/inferno-core";
// import { ObservableMap } from "mobx";

const sdk = getSdk(sdkOpts());

export interface StationTrackOptions {
  slug: string;
  paging: {
    take: number;
    nextCursor?: string;
    prevCursor?: string;
  };
}

export const getTrackHistoryWithCurrentlyPlayingCacheKey = (opts: Webapi.GetCurrentlyPlayingSongsQueryVariables) =>
  `getTrackHistoryWithCurrentlyPlayingCacheKey-${opts.slug}`;

export const getTrackHistoryWithCurrentlyPlaying = (
  variables: Webapi.GetCurrentlyPlayingSongsQueryVariables,
  cache: Map<string, ICache>,
) =>
  new Cursor<
    Webapi.CurrentlyPlayingFragment | undefined,
    Webapi.GetCurrentlyPlayingSongsQueryVariables,
    Webapi.CurrentlyPlayingTrackDetailFragment
  >(
    variables,
    async (opts: Webapi.GetCurrentlyPlayingSongsQueryVariables) => {
      return sdk!.GetCurrentlyPlayingSongs.queryAsPromise(opts).then(response => {
        if (!response.data?.sites.find?.stream?.amp.currentlyPlaying?.tracks?.length) {
          return;
        }
        return response.data.sites.find.stream?.amp.currentlyPlaying;
      });
    },
    (resp: Webapi.CurrentlyPlayingFragment | undefined, opts: Webapi.GetCurrentlyPlayingSongsQueryVariables) => {
      const newData = resp?.tracks || [];
      const hasMore = !!resp?.pageInfo?.nextCursor;
      const newOpts = {
        ...opts,
        paging: { ...opts.paging, nextCursor: resp?.pageInfo?.nextCursor || "" },
      };
      return { opts: newOpts, hasMore, newData };
    },
    getTrackHistoryWithCurrentlyPlayingCacheKey,
    cache,
    {
      optsClean: options => {
        const newOpts = { ...options };
        delete newOpts.paging?.nextCursor;
        return newOpts;
      },
    },
  );

export const getTopTracks = (variables: Webapi.GetTopSongsQueryVariables, cache: Map<string, ICache>) =>
  new Cursor<Webapi.TopTracksFragment | undefined, Webapi.GetTopSongsQueryVariables, Webapi.TopTrackDetailFragment>(
    variables,
    async (opts: Webapi.GetTopSongsQueryVariables) => {
      return sdk!.GetTopSongs.queryAsPromise(opts).then(response => response?.data?.sites.find?.stream?.amp?.topTracks);
    },
    (resp: Webapi.TopTracksFragment | undefined, opts: Webapi.GetTopSongsQueryVariables) => {
      const newData = resp?.tracks || [];
      const hasMore = !!resp?.pageInfo.nextCursor;
      const newOpts = { ...opts, paging: { ...opts.paging, nextCursor: resp?.pageInfo.nextCursor || "" } };
      return { opts: newOpts, hasMore, newData };
    },
    (opts: Webapi.GetTopSongsQueryVariables) => `getTopTracks-${opts.slug}-${JSON.stringify(opts.paging)}`,
    cache,
  );

// ts-prune-ignore-next
export const getCurrentTrack = async (query: Webapi.GetCurrentTrackQueryVariables, surrogateKeys: Set<string>) => {
  surrogateKeys.add("ihr-amp");
  return sdk!.GetCurrentTrack.queryAsPromise(query).then(
    response => response?.data?.sites.find?.stream?.amp?.currentTrack,
  );
};
