import loadable from "@loadable/component";
import { inject } from "mobx-react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { RemoteList } from "../../components/remote/RemoteList.component";
import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import { MusicPageType } from "../../lib/catalog";
import { getTopTracks, StationTrackOptions } from "../../services/LiveMeta";
import { LoadMoreFromCursor } from "../../ui";
import type { Store } from "@inferno/renderer-shared-core";

const TrackList = loadable(() => import("../../components/catalog/TrackList.component"));
const TopTrackList = loadable(() => import("./TopTrack.component"));

interface TopTrackLoaderProps {
  store?: Store;
  block: BlockFragment;
  isPrimary: boolean;
  songsToTake?: number;
  onMusicPage?: boolean;
}

export const TopTrackLoader = inject("store")(
  ({ store, block, isPrimary, songsToTake = 5, onMusicPage }: TopTrackLoaderProps) => {
    if (!store) {
      return null;
    }
    const { t } = useTranslation();

    const topTracksOptions: StationTrackOptions = {
      slug: store.site.index.slug,
      paging: {
        take: songsToTake,
      },
    };
    return (
      <RemoteList cursor={getTopTracks(topTracksOptions, store.cache)} showLoading={isPrimary}>
        {topTracks => {
          const topSongsData = !!topTracks.data?.length;
          return topSongsData ? (
            <Fragment>
              {onMusicPage ? (
                <Fragment>
                  <TopTrackList block={block} data={topTracks.data} />
                  <LoadMoreFromCursor
                    hasNext={topTracks.hasNext}
                    nextFn={topTracks.next}
                    loading={topTracks.loading}
                    sectionName="top_songs"
                  />
                </Fragment>
              ) : (
                <TrackList data={topTracks.data} pageType={MusicPageType.TOP} />
              )}
            </Fragment>
          ) : (
            <div>{t("no_top_songs")}</div>
          );
        }}
      </RemoteList>
    );
  },
);
